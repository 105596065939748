<template>
  <HeaderBlock msg="HeaderBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mt-5 mb-5" style="font-size: xx-large;">ULooper Updater</h1>
          </div>
        </div>
      </div>
    </div> <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="card card-lg">
          <div class="row g-4">
            <div class="col-md-12">
              <div class="card-body">
                <h2>Windows</h2>
                <!-- <div class="alert alert-warning" role="alert">
                  <div class="d-flex">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z">
                        </path>
                        <path d="M12 9v4"></path>
                        <path d="M12 17h.01"></path>
                      </svg>
                    </div>
                    <div>
                      If you encounter a virus alert when downloading the firmware file, please ensure your Windows
                      Security Center or any antivirus software are updated with the latest virus definitions. Once
                      updated, the download should proceed without issues.
                      <br />If you need a decompression
                      password, please enter "xsonic".
                    </div>

                  </div>
                </div> -->

                <ul>
                  <li v-for="item in versions" v-bind:key="item">
                    <a :href="'/upload/ulooper_updater/ULooper_Firmware_Updater_Win_v' + item.v+(item.beta?'_Beta':'')+ '.zip?v=4'">ULooper_Firmware_Updater_Win_v{{
                    item.v+(item.beta?'_Beta':'')
                  }}.zip</a>
                  </li>
                </ul>
                <h2>MacOS</h2>
                <ul>
                  <li v-for="item in versions" v-bind:key="item">
                    <a :href="'/upload/ulooper_updater/ULooper_Firmware_Updater_Mac_v' + item.v+(item.beta?'_Beta':'')+ '.zip?v=4'">ULooper_Firmware_Updater_Mac_v{{
                    item.v+(item.beta?'_Beta':'')
                  }}.zip</a>
                  </li>
                </ul>
                <h2>Release Note</h2>  
                <h3>v1.2.5</h3>
                <ul>
                  <li>Added support for KATANA 50 MK1, MK2, GEN3, and higher models.</li>
                  <li>Added support for KATANA AIR & AIR EX.</li>
                </ul>
                <!-- <div>Note: This is a beta version and may experience instability during use.</div><br/> -->
                <h3>v1.2.4</h3>
                <ul>
                  <li>Added support for Spark 2.</li>
                  <li>Fixed bugs affecting certain Yamaha THR models.</li>
                </ul>
                <h3>v1.2.3</h3>
                <ul>
                  <li>Added support for KATANA GO. </li>
                  <li>Added support for Fender LT25, LT40S, LT50. </li>
                  <li>Fixed various bugs. </li>
                </ul>
                <h3>v1.2.2</h3>
                <ul>
                  <li>Fixed the issue of unsuccessful connections with some THR30IIA.</li>
                </ul>
                <h3>v1.2.1</h3>
                <ul>
                  <li>Added support for Marshall CODE Series.</li>
                  <li>Fixed various bugs.</li>
                </ul>
                <h3>v1.2.0</h3>
                <ul>
                  <li>Added support for YAMAHA THR-II Series.</li>
                  <li>Fixed various bugs.</li>
                </ul>
                <h3>v1.1.9</h3>
                <ul>
                  <li>Added support for Spark 40 with a 44.1kHz sample rate.</li>
                  <li>Enhanced operational stability.</li>
                </ul>
                <h3>v1.1.8</h3>
                <ul>
                  <li>Original Release: Supports Spark 40, Spark MINI, Spark GO, and Spark Live.</li>
                </ul>
                <!-- <h2>Private Test</h2>
                <li>
                  <a
                    href="/upload/ulooper_updater_download/ULooper_Win_Private_Test_v1.1.4.zip">ULooper_Win_Private_Test_v1.1.4.zip</a>
                </li>
                <li>
                  <a
                    href="/upload/ulooper_updater_download/ULooper_Mac_Private_Test_v1.1.4.zip">ULooper_Mac_Private_Test_v1.1.4.zip</a>
                </li> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>
<script>
import HeaderBlock from '../components/HeaderBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
//
import { global } from '../global.js'
export default {
  name: 'ULooperUpdaterPage',
  data() {
    return {
      global: global,
      versions: [ {
          v: '1.2.5',
          beta: false
        },
        {
          v: '1.2.4',
          beta: false
        },
        {
          v: '1.2.3',
          beta: false
        },
      ],
      i18n: "en",
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
  },
}
</script>
<style scoped>
.card-body {
  padding: 5%;
}
</style>